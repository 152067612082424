import * as React from "react";
import Img from "gatsby-image";

import { ContentfulPrint } from "../../graphql-types";
import { Title, ImageContainer } from "./style";

interface Props {
  print: ContentfulPrint;
}

export default function PrintCard({ print }: Props) {
  return (
    <>
      {print.preview && (
        <ImageContainer>
          <Img fluid={print.preview.fluid} />
        </ImageContainer>
      )}
      <Title>{print.title}</Title>
    </>
  );
}
