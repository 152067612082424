import * as React from "react";

import AnimatedList from "../animated-list";
import { Wrapper } from "./style";

interface Props<C> {
  cards: C[];
  children: (card: C) => React.ReactNode;
  keyFinder: (card: C) => string;
}

export default function CardsGrid<C>({ cards, children, keyFinder }: Props<C>) {
  return (
    <Wrapper>
      <AnimatedList items={cards} keyFinder={keyFinder}>
        {children}
      </AnimatedList>
    </Wrapper>
  );
}
