import styled from "styled-components";

export const ImageContainer = styled.div`
  background-color: #2d0e2f;
  padding-bottom: -50%;
  position: relative;
`;

export const Title = styled.h3`
  font-family: Fira Sans;
  text-transform: uppercase;
  margin: 10px 0 0 0;
  color: #d66569;

  @media screen and (max-width: 768px) {
    padding: 15px;
  }
`;
