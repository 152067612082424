import * as React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Title from "../components/title";
import Blocks from "../components/content-blocks";
import { Query } from "../graphql-types";
import PageContainer from "../components/page-container";
import PageHeader from "../components/page-header";
import CardsGrid from "../components/cards-grid";
import PrintCard from "../components/print-card";
import SEO from "../components/seo";

interface Props {
  data: Query;
}

export default function Prints({ data }: Props) {
  const page = data.allContentfulPrintsPage.edges
    .map(e => e.node)
    .find((_, i) => i === 0);

  return (
    <Layout>
      <SEO data={page.seo} />
      <PageContainer>
        <PageHeader>
          <Title>{page.title}</Title>
        </PageHeader>

        <Blocks blocks={page.blocks} />
        <Title style={{ marginTop: 60 }}>Tirages</Title>
        <CardsGrid cards={page.prints} keyFinder={({ id }) => id}>
          {print => (
            <Link to={`/print/${print.slug}`}>
              <PrintCard print={print} />
            </Link>
          )}
        </CardsGrid>
      </PageContainer>
    </Layout>
  );
}

export const query = graphql`
  {
    allContentfulPrintsPage {
      edges {
        node {
          title
          seo {
            ...SEO
          }
          blocks {
            id
            size
            color {
              value
            }
            image {
              fluid(maxWidth: 1400, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
            content {
              childMarkdownRemark {
                html
              }
            }
            titleColor {
              value
            }
            textColor {
              value
            }
          }
          prints {
            id
            title
            slug
            preview {
              fluid(maxWidth: 600, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  }
`;
